"use client";

import { HeaderMenu, NavItem, NavParent } from "@/components/layout/Header/HeaderMenu";
import { ButtonLink } from "@/components/shared/Button/Button";
import { LinkSolstice } from "@/components/shared/LinkSolstice/LinkSolstice";
import IconHamburger from "@/images/icons/hamburger.svg";
import Logo from "@/images/logos/logo.svg";
import { categories, SiteName, siteName } from "@/utilities/constants";
import clsx from "clsx";
import { useState } from "react";
import { PagesQueryTasResult, PagesQueryVicResult } from "../../../sanity/types";
import styles from "./Header.module.scss";

interface HeaderProps {
	pages: PagesQueryTasResult | PagesQueryVicResult;
}

export const Header = ({ pages }: HeaderProps) => {
	const [isMobileExpanded, setIsMobileExpanded] = useState(false);

	return (
		<header className={styles["header"]}>
			{/* TODO: Emergency Banner */}
			<nav className={styles["header__wrapper"]}>
				<div className={styles["header__secondary"]}>
					<ul className={styles["header__secondary-list"]}>
						<li>
							<LinkSolstice
								className={styles["header__secondary-link"]}
								href={"https://tasgasnetworks.com.au/network-alerts"}
							>
								Gas Network Alerts
							</LinkSolstice>
						</li>
						<li>
							<LinkSolstice
								className={styles["header__secondary-link"]}
								href={"/contact-us"}
							>
								Contact us
							</LinkSolstice>
						</li>
						<li>
							<a
								className={clsx(
									styles["header__secondary-link"],
									styles["header__secondary-link--large"],
									siteName === SiteName.Solstice && styles["header__active-region"],
								)}
								href={process.env.NEXT_PUBLIC_TAS_SITE}
							>
								TAS
							</a>
							{" / "}
							<a
								className={clsx(
									styles["header__secondary-link"],
									styles["header__secondary-link--large"],
									siteName === SiteName.SolsticeVictoria && styles["header__active-region"],
								)}
								href={process.env.NEXT_PUBLIC_VIC_SITE}
								onClick={
									process.env.NODE_ENV === "development"
										? e => {
												if (
													!confirm(
														"You are being redirected/rewritten to a staging/production VIC site. If you want to access the local VIC site, build and run it locally.\r\n\r\nDo you want to continue?",
													)
												) {
													e.preventDefault();
												}
											}
										: undefined
								}
							>
								VIC
							</a>
						</li>
					</ul>
				</div>
				<div className={styles["header__primary"]}>
					<LinkSolstice
						className={styles["header__logo-link"]}
						href="/"
					>
						<span className="sr-only">Home</span>
						<Logo className={styles["header__logo"]} />
					</LinkSolstice>
					<HeaderMenu
						className={styles["header__menu"]}
						navItems={categories[process.env.NEXT_PUBLIC_SITE as SiteName].map(category => {
							const items = pages.filter(
								page => page.category === category.value && page.visibility,
							);

							return {
								id: category.value,
								label: category.title,
								children: items.map(
									item =>
										({
											id: item.slug?.current || "",
											label: item.title,
											href: item.slug?.current || "",
											displayInHeader: item.visibility,
										}) as NavItem,
								),
							} as NavParent;
						})}
						isMobileExpanded={isMobileExpanded}
						setIsMobileExpanded={setIsMobileExpanded}
					/>
					<div className={styles["header__actions"]}>
						{process.env.NEXT_PUBLIC_SITE === SiteName.Solstice && (
							<ButtonLink
								href={"/apply-online"}
								label="Join Now"
							/>
						)}
					</div>
					<div className={styles["header__mobile-actions"]}>
						{process.env.NEXT_PUBLIC_SITE === SiteName.Solstice && (
							<LinkSolstice
								className={styles["header__secondary-link"]}
								href={"/apply-online"}
							>
								Join
							</LinkSolstice>
						)}
						<button
							className={styles["header__hamburger"]}
							onClick={() => setIsMobileExpanded(!isMobileExpanded)}
							aria-label="Expand Navigation Menu"
							aria-expanded={isMobileExpanded ? "true" : "false"}
						>
							<IconHamburger />
						</button>
					</div>
				</div>
			</nav>
		</header>
	);
};
